import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Button = makeShortcode("Button");
const Icon = makeShortcode("Icon");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Box = makeShortcode("Box");
const StatusBanner = makeShortcode("StatusBanner");
const Tout = makeShortcode("Tout");
const Column = makeShortcode("Column");
const Accordion = makeShortcode("Accordion");
const Process = makeShortcode("Process");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Style Guide`}</h1>
    <p>{`Sure, there are seemingly infinite ways you can customize page content, but try not to get carried away. These guidelines are meant to keep page content and component usage `}<strong parentName="p">{` `}<em parentName="strong">{`visually`}</em>{` `}</strong>{` consistent across this site, which improves the overall user and reader experience.`}</p>
    <p>{`Above all else, `}<strong parentName="p">{` `}<em parentName="strong">{`be consistent.`}</em>{` `}</strong></p>
    <Callout icon="lightbulb" mdxType="Callout">
      <p>{`For a broader overview of all components, check out the `}<a parentName="p" {...{
          "href": "/resources/technical-content/palette-recipes"
        }}>{`Component Palette`}</a>{`. For more information about using components in MDX, read our other tutorials on `}<a parentName="p" {...{
          "href": "/develop/content"
        }}>{`Crafting Technical Content`}</a>{`.`}</p>
    </Callout>
    <h2>{`Buttons`}</h2>
    <p>{`Buttons communicate actions that users can take.`}</p>
    <p>{`All buttons should`}</p>
    <ul>
      <li parentName="ul">{`Start with an “action” verb`}</li>
      <li parentName="ul">{`Use sentence case`}</li>
      <li parentName="ul">{`Express what action will occur when the button is clicked`}</li>
    </ul>
    <p><strong parentName="p">{`Primary (solid) buttons`}</strong>{` should be used for highest priority actions.`}</p>
    <Button primary to="/resources/palette" mdxType="Button">
  {" "}
  Primary{" "}
    </Button>
    <p><strong parentName="p">{`Secondary (solid) buttons`}</strong>{` should be used for high priority actions, alongside a primary button.`}</p>
    <Button primary inline to="/resources/palette" mdxType="Button">
  {" "}
  Primary{" "}
    </Button>
    <Button secondary inline to="/resources/palette" mdxType="Button">
  {" "}
  Secondary{" "}
    </Button>
    <p><strong parentName="p">{`Outline buttons`}</strong>{` should be used for medium priority actions alongside primary buttons.`}</p>
    <Button primary inline to="/resources/palette" mdxType="Button">
  {" "}
  Primary{" "}
    </Button>
    <Button outline inline to="/resources/palette" mdxType="Button">
  {" "}
  Outline{" "}
    </Button>
    <p><strong parentName="p">{`Icons`}</strong>{` can be added to any button and should be used to visually emphasize a button's action.`}</p>
    <Button primary to="/resources/palette" mdxType="Button">
  {" "}
  <Icon name="rocketchat" mdxType="Icon" /> Join our chat{" "}
    </Button>
    <h2></h2>
    <h4><strong parentName="h4">{`Things to avoid`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Using more than one icon in a button`}</li>
    </ul>
    <Button to="#" mdxType="Button">
  <Icon name="rocketchat" mdxType="Icon" /> Rocket Chat
    </Button>
    <ul>
      <li parentName="ul">{`Using two of the same kind of button side by side`}</li>
    </ul>
    <Button primary inline to="/resources/palette" mdxType="Button">
  {" "}
  Primary{" "}
    </Button>
    <Button primary inline to="/resources/palette" mdxType="Button">
  {" "}
  Primary{" "}
    </Button>
    <h4>{`Buttons vs. Links`}</h4>
    <p>{`Links are mostly used for navigation purposes (as opposed to actions), and usually appear within a sentence or directly following. Links are used to navigate to a different page in the site, navigate to a different site, or jump to an element on the same page.`}</p>
    <h2></h2>
    <h2>{`Lists`}</h2>
    <p>{`Lists are a continuous collection of related text or external links. List items have two parameters, 1-line/2-line and link/no link.`}</p>
    <h4>{`1-line, no link`}</h4>
    <List mdxType="List">
List item
      <p>{`List item`}</p>
      <p>{`List item`}</p>
    </List>
    <h4>{`1-line, link`}</h4>
    <List mdxType="List">
      <Link to="/" mdxType="Link">
        <p>{`List item`}</p>
      </Link>
      <Link to="/" mdxType="Link">
        <p>{`List item`}</p>
      </Link>
      <Link to="/" mdxType="Link">
        <p>{`List item`}</p>
      </Link>
    </List>
    <h4>{`2-line, no link`}</h4>
    <List mdxType="List">
      <Box mdxType="Box">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Box>
      <Box mdxType="Box">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Box>
      <Box mdxType="Box">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Box>
    </List>
    <h4>{`2-line, link`}</h4>
    <List mdxType="List">
      <Link to="https://makerdao.com" mdxType="Link">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Link>
      <Link to="/learn" mdxType="Link">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Link>
      <Link to="/learn" mdxType="Link">
        <p>{`List item`}</p>
        <p>{`Sub content`}</p>
      </Link>
    </List>
    <p>{`List items should start with a capital letter and be written in sentence case.`}</p>
    <h4><strong parentName="h4">{`Things to avoid`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`putting 1-line list items in the same list as 2-line list items, and vice versa.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`putting a list item with no link in the same list as list items with links, and vice versa.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`putting a list inside a call-out, tout, or 2-column comparison.`}</p>
        <h3 parentName="li"></h3>
        <List mdxType="List">
          <p parentName="li">{`List item`}</p>
          <Box mdxType="Box">
            <p parentName="li">{`List item`}</p>
            <p parentName="li">{`Sub Content`}</p>
          </Box>
          <Link to="/" mdxType="Link">
            <p parentName="li">{`List item`}</p>
          </Link>
        </List>
      </li>
    </ul>
    <p>{`In the event that you need to list multiple ideas within a 2-column comparison, use a default, unstyled unordered list.`}</p>
    <p>{`Lists `}<strong parentName="p">{`can`}</strong>{` go inside process steppers and accordions, where it is appropriate to have more content.`}</p>
    <h4>{`List components vs. unstyled, unordered lists`}</h4>
    <p><strong parentName="p">{`List components`}</strong>{` should be used for collections of links, events, or other series of related, actionable content.`}</p>
    <p><strong parentName="p">{`Unstyled, unordered lists`}</strong>{` should be used for series of `}<strong parentName="p">{`ideas`}</strong>{` that are directly connected to the text preceding, as seen in this style guide.`}</p>
    <h2>{`Status Banners`}</h2>
    <p>{`Status banners are concise, high-priority alerts that appear at the top of a page. Status banners are meant to catch a user’s attention quickly before proceeding to page content. Status banners can contain optional action items in the form of text buttons.`}</p>
    <p>{`Status banners should be used for messaging that informs or changes how a user will interact with the entirety of the page’s content, as it is the first thing the user will see (e.g. “Funding is closed” on a page about how to get funding). Think of a status banner as a preface to the page content, or a “before you proceed.”`}</p>
    <p>{`Status banners should be placed `}<strong parentName="p">{`below breadcrumbs and above the page header`}</strong>{` and be succinct, focused, and only contain one message.`}</p>
    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`Funding for projects is closed until September 1, 2020`}</strong></p>
    </StatusBanner>
    <h4><strong parentName="h4">{`Things to avoid`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Using status banners inside other components (touts, lists, two-column comparison, chocolate box, etc.)`}</li>
      <li parentName="ul">{`Using more than one status banner per page`}</li>
    </ul>
    <h2>{`Callouts`}</h2>
    <p>{`Callouts are highlighted blocks of text used to highlight medium-priority information within a page’s content. Callouts are meant to be helpful without being too disruptive.`}</p>
    <p>{`Callouts shoud clearly articulate the benefit of the feature and what it does and use icons to help communicate.`}</p>
    <Callout icon="search" secondary mdxType="Callout">
      <p>{`This is a callout with the (specified) secondary style and the "search' icon from dai-ui-icons!`}</p>
    </Callout>
    <h2>{`Touts`}</h2>
    <p>{`Touts are cards of content used to highlight high or medium priority information. A primary use case for touts is guiding a reader to relevant information on another page.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`Working on a bounty`}</h2>
        <p>{`See what's expected of you if you work on a Maker community bounty.`}</p>
        <p><a parentName="p" {...{
            "href": "#"
          }}>{`Working on a bounty`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Say hi!`}</h2>
        <p>{`The best place to start is in our chat. Say hello and get to know us.`}</p>
        <Link mdxType="Link">Community chat</Link>
      </Box>
    </Tout>
    <h2>{`2-column text`}</h2>
    <p>{`Two-column comparisons are a way to organize content that fits into two distinct, mutually exclusive, but related categories, most often for the purpose of comparison. Two-column text helps make important, text-only content more digestible and connected.`}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h4>{`Writer`}</h4>
        <p>{`Writers take initial notes, screenshots, and fix notes in post-editing with the availability of the recording.`}</p>
        <p>{`We recommend at least two people do this role, splitting up the work for the entire summary. Multiple writers have the advantage of supporting each other with formatting, screenshots, and quality assurance on sections that they are not taking the initial notes on.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Reviewer`}</h4>
        <p>{`Reviwers set up the initial working doc, do a full review of the summary after it's produced, quality check for formatting and semantic errors, and also submit the final version of the summary to GitHub and the Forum. Recommended to be done by one person.`}</p>
      </Box>
    </Column>
    <h2>{`Accordion`}</h2>
    <p>{`Accordions are used to organize and condense content that does not need to be displayed on page load. This allows users to opt into seeing expanded based on their headers, which shortens scrolling through nonessential information.`}</p>
    <p>{`Accordions should generally have three or more "folds." In the case of there being only two folds, a two-column comparison will likely suffice.`}</p>
    <p>{`An ideal use case for accordions is `}<strong parentName="p">{`FAQs`}</strong>{`, or series of questions and answers.`}</p>
    <Accordion mdxType="Accordion">
      <p>{`How should I format the headers of my accordion?`}</p>
      <p>{`Every header in your accordion should be formatted the same. Avoid straying from the default text formatting, but if you must, make sure it is consistently applied to all of your accordion "folds."`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p>{`Can I change the icons at the end of my accordion?`}</p>
      <p>{`Technically, but it creates confusion if there are different icons being used for the same purpose.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p>{`Can I include other components inside an accordion?`}</p>
      <p>{`Yes! Accordions are simply a way to minimize page content, not restrict it, so there is a good amount of freedom for what goes inside.`}</p>
      <p><strong parentName="p">{`However,`}</strong>{` avoid putting high-priority content inside an accordion (like touts or buttons), because, well, if it's high-priority you don't want to hide it!`}</p>
    </Accordion>
    <h2></h2>
    <h2>{`Process stepper`}</h2>
    <p>{`Process steppers are numbered lists that make important or complicated processes more digestible.`}</p>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h3>{`Step 1`}</h3>
        <p>{`Make sure the headers and content of every step are formatted the same.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Step 2`}</h3>
        <p>{`Add your content! You can nest components inside of here if they further simplify instructions, though be wary of convoluting information with frivolous components.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Step 3`}</h3>
        <p>{`Admire your process stepper!`}</p>
      </Box>
    </Process>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      